import { APICompsSchema } from "@/apis/api-config";

import { prefectureJpEnMap } from "./jp-prefecture";

import { LocalForageKeys } from "@/constants/local-forage-keys";
import { UseQueryResult } from "@tanstack/react-query";
import localforage from "localforage";
import { parse } from "path";
import { ParsedUrlQuery } from "querystring";

export const int = (v: any): number => (v && parseInt(v)) || 0;

export const zeroPad = (num: number, length: number) => ("0000000000" + int(num)).slice(-int(length));

export const flattenObject = (obj: any, roots = [], sep = "."): any =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === "[object Object]"
          ? flattenObject(obj[prop], roots.concat([prop as never]), sep)
          : { [roots.concat([prop as never]).join(sep)]: obj[prop] },
      ),
    {},
  );

export const createUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (a) {
    const r = (new Date().getTime() + Math.random() * 16) % 16 | 0,
      v = a == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const stringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const stringAvatarProps = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.slice(0, 1)}`,
  };
};

export const isIncompletePath = (path: string) => {
  const targetParams = ["[id]", "[type]", "[current_ws]", "[task_id]"];
  return targetParams.some((p) => path.includes(p));
};

// カスタムdebounce関数
export const debounce = <T extends (...args: any[]) => any>(func: T, wait: number): T => {
  let timeout: NodeJS.Timeout | null;
  // 引数で渡された関数(func)をデバウンス処理する新しい関数を返す
  return ((...args: Parameters<T>): void => {
    // 既存のタイムアウトをクリア
    if (timeout) {
      clearTimeout(timeout);
    }
    // 新しいタイムアウトを設定
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  }) as T;
};

export const getCurrentWorkspaceId = async (query: ParsedUrlQuery) => {
  return query.current_ws || (await localforage.getItem(LocalForageKeys.app.currentWorkspaceId)) || "";
};

export const staffToFullName = (staff: APICompsSchema["WorkspaceUserObject"]): string => {
  return `${staff.last_name}${staff.first_name}`;
};

export const isSuccessQueryResults = (queryResults: UseQueryResult[]) => {
  return queryResults.every((r) => r.isSuccess);
};

export const setContenteditable = (rowId: string, field: string, editable = true) => {
  setTimeout(() => {
    const elem = document.querySelector(`div[data-id="${rowId}"]`)?.querySelector(`div[data-field="${field}"]`);
    elem?.setAttribute("contenteditable", editable ? "true" : "false");
    elem?.classList.add("MuiDataGrid-cell-contenteditable");
  });
};

export const splitIgnoringQuotes = (str: string, delimiter: string): string[] => {
  const result: string[] = [];
  let insideQuotes = false;
  let currentWord = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === '"') {
      insideQuotes = !insideQuotes;
      currentWord += char;
    } else if (char === delimiter && !insideQuotes) {
      result.push(currentWord);
      currentWord = "";
    } else {
      currentWord += char;
    }
  }

  result.push(currentWord);

  return result;
};

export const duplicatedArray = (array1: Array<any>, array2: Array<any>) => {
  const tempList = [...array1, ...array2].filter((item) => array1.includes(item) && array2.includes(item));
  return [...new Set(tempList)];
};

export const getFormattedAddress = (address: {
  prefecture: APICompsSchema["AddressObject"]["prefecture"];
  city: APICompsSchema["AddressObject"]["city"];
  street: APICompsSchema["AddressObject"]["street"];
  building?: APICompsSchema["AddressObject"]["building"];
}) => {
  return `${address.prefecture ? prefectureJpEnMap[address.prefecture] : ""}${address.city || ""}${
    address.street || ""
  }${address.building || ""}`;
};

export const isJsonString = (word: string) => {
  try {
    const parsed = JSON.parse(word);
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false;
  }
};
export const isSequential = (arr: Array<number>): boolean => {
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] - arr[i - 1] !== 1) {
      return false;
    }
  }
  return true;
};
export const areAllItemsEqualInArray = (arr: Array<any>): boolean => {
  if (arr.length === 0) return true;

  const sortedFirstItem = arr[0].slice().sort().toString();

  for (let i = 1; i < arr.length; i++) {
    const sortedItem = arr[i].slice().sort().toString();
    if (sortedItem !== sortedFirstItem) {
      return false;
    }
  }

  return true;
};
