import { useRouter } from "next/router";
import { useCallback } from "react";

import { APIPaths } from "@/apis/api-config";
import { AuthApi } from "@/apis/connect/auth-api";
import { UserApi } from "@/apis/connect/user-api";
import { useApiConfig } from "@/custom-hooks/apis-common/use-api-config";
import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";
import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { getCurrentWorkspaceId, isIncompletePath } from "@/utils/common-utils";
import { firebaseAuth } from "@/utils/firebase-utils";

import { AUTH_INDEX_PATH, TASK_INDEX_PATH } from "@/constants/app-path";
import { LocalForageKeys } from "@/constants/local-forage-keys";
import { currentStaffAtom, currentUserAtom } from "@/global-states/api-atoms";
import { authTokenInfoAtom, fbUserCredentialAtom, loadingAtom } from "@/global-states/common-atoms";
import { signInWithCustomToken, signOut } from "@firebase/auth";
import { useAtom, useSetAtom } from "jotai";
import localforage from "localforage";

export const useAuth = () => {
  const router = useRouter();
  const { setAppSnackbar } = useAppSnackbar();
  const { appAuthApi, appWorkspaceUserApi, appUserApi } = useApiConnector();
  const { resetAuth, catchApiError, getRefreshToken } = useApiConfig();

  const setLoading = useSetAtom(loadingAtom);
  const setCurrentUser = useSetAtom(currentUserAtom);
  const [currentStaff, setCurrentStaff] = useAtom(currentStaffAtom);
  const [authTokenInfo, setAuthTokenInfo] = useAtom(authTokenInfoAtom);

  const setFbUserCredential = useSetAtom(fbUserCredentialAtom);

  const authSync = useCallback(
    async (opts?: { silent?: boolean }) => {
      if (isIncompletePath(router.asPath)) {
        return;
      }
      if (!opts?.silent) {
        setLoading(true);
      }
      try {
        const refreshToken = await getRefreshToken();
        if (!refreshToken) {
          await resetAuth();
          return false;
        }
        const refreshAuthApi = new AuthApi(refreshToken);
        const resultRefresh = await refreshAuthApi.postRenewToken();
        setAuthTokenInfo(resultRefresh);
        if (!opts?.silent) {
          const appUserApi = new UserApi(resultRefresh.access_token);
          const resultUser = await appUserApi.getUserMe();
          setCurrentUser(resultUser.object);
          const staffs = resultUser?.object?.workspace_users || [];
          const currentWsId = await getCurrentWorkspaceId(router.query);
          const staff = staffs.find(({ workspace_id: wsId }) => currentWsId === wsId) || staffs[0] || null;
          setCurrentStaff(staff);
        }
        return true;
      } catch (err) {
        catchApiError(err);
        await resetAuth();
        return false;
      } finally {
        if (!opts?.silent) {
          setLoading(false);
        }
      }
    },
    [authTokenInfo?.access_token, router.asPath],
  );

  const fbAuthSync = async (fbCustomToken?: string) => {
    if (!fbCustomToken) {
      try {
        setFbUserCredential(undefined);
        await signOut(firebaseAuth);
      } catch (e) {
        console.warn("### failed signOut for firebase. ", e);
      }
    } else {
      try {
        const userCredential = await signInWithCustomToken(firebaseAuth, fbCustomToken);
        setFbUserCredential(userCredential);
      } catch (e) {
        console.error("### failed signInWithCustomToken for firebase. ", e);
      }
    }
  };

  const authSignUpEmail = useCallback(
    async (
      requestBody: APIPaths["/app/auth/signup-with-email"]["post"]["requestBody"]["content"]["application/json"],
    ) => {
      setLoading(true);
      try {
        const resultSignUp = await appAuthApi.postSignupWithEmail(requestBody);
        setAuthTokenInfo(resultSignUp);
        await localforage.setItem(LocalForageKeys.auth.AccessToken, resultSignUp.access_token);
        await localforage.setItem(LocalForageKeys.auth.RefreshToken, resultSignUp.refresh_token);
        const appUserApi = new UserApi(resultSignUp.access_token);
        const resultUser = await appUserApi.getUserMe();
        setCurrentUser(resultUser.object);
        setCurrentStaff(resultUser?.object?.workspace_users?.[0] || null);
        setAppSnackbar("新規登録に成功しました");
        return true;
      } catch (err) {
        catchApiError(err);
        await resetAuth();
        return false;
      } finally {
        setLoading(false);
      }
    },
    [appAuthApi],
  );

  const authSignUpGoogle = useCallback(
    async (
      requestBody: APIPaths["/app/auth/signup-with-sns"]["post"]["requestBody"]["content"]["application/json"],
    ) => {
      setLoading(true);
      try {
        const resultSignUp = await appAuthApi.postSignupWithSns(requestBody);
        setAuthTokenInfo(resultSignUp);
        await localforage.setItem(LocalForageKeys.auth.AccessToken, resultSignUp.access_token);
        await localforage.setItem(LocalForageKeys.auth.RefreshToken, resultSignUp.refresh_token);
        const appUserApi = new UserApi(resultSignUp.access_token);
        const resultUser = await appUserApi.getUserMe();
        setCurrentUser(resultUser.object);
        setCurrentStaff(resultUser?.object?.workspace_users?.[0] || null);
        setAppSnackbar("新規登録に成功しました");
        return true;
      } catch (err) {
        catchApiError(err);
        await resetAuth();
        return false;
      } finally {
        setLoading(false);
      }
    },
    [appAuthApi],
  );

  const authLoginWithEmail = useCallback(
    async (
      requestBody: APIPaths["/app/auth/login-with-email"]["post"]["requestBody"]["content"]["application/json"],
    ) => {
      setLoading(true);
      try {
        const resultLogin = await appAuthApi.postLoginWithEmail(requestBody);
        setAuthTokenInfo(resultLogin);
        await localforage.setItem(LocalForageKeys.auth.AccessToken, resultLogin.access_token);
        await localforage.setItem(LocalForageKeys.auth.RefreshToken, resultLogin.refresh_token);
        const appUserApi = new UserApi(resultLogin.access_token);
        const resultUser = await appUserApi.getUserMe();
        setCurrentUser(resultUser.object);
        setCurrentStaff(resultUser?.object?.workspace_users?.[0] || null);
        setAppSnackbar("ログインに成功しました", {
          autoCloseMSec: 10000,
        });
        return await router.push(TASK_INDEX_PATH);
      } catch (err) {
        catchApiError(err);
        await resetAuth();
      } finally {
        setLoading(false);
      }
    },
    [appAuthApi],
  );

  const authLoginWithSns = useCallback(
    async (requestBody: APIPaths["/app/auth/login-with-sns"]["post"]["requestBody"]["content"]["application/json"]) => {
      setLoading(true);
      try {
        const resultLogin = await appAuthApi.postLoginWithSns(requestBody);
        setAuthTokenInfo(resultLogin);
        await localforage.setItem(LocalForageKeys.auth.AccessToken, resultLogin.access_token);
        await localforage.setItem(LocalForageKeys.auth.RefreshToken, resultLogin.refresh_token);
        const appUserApi = new UserApi(resultLogin.access_token);
        const resultUser = await appUserApi.getUserMe();
        setCurrentUser(resultUser.object);
        setCurrentStaff(resultUser?.object?.workspace_users?.[0] || null);
        setAppSnackbar("ログインに成功しました", {
          autoCloseMSec: 10000,
        });
        return await router.push(TASK_INDEX_PATH);
      } catch (err) {
        catchApiError(err);
        await resetAuth();
      } finally {
        setLoading(false);
      }
    },
    [appAuthApi],
  );

  const authLogout = useCallback(async () => {
    setLoading(true);
    try {
      await router.push(AUTH_INDEX_PATH);
      await resetAuth();
      return setAppSnackbar("ログアウトしました");
    } catch (err) {
      catchApiError(err);
    } finally {
      setLoading(false);
    }
  }, [appAuthApi]);

  const syncUserMe = useCallback(async () => {
    if (isIncompletePath(router.asPath)) {
      return;
    }

    setLoading(true);
    try {
      const result = await appUserApi.getUserMe();
      setCurrentUser(result.object);
      const staffs = result?.object?.workspace_users || [];
      const currentWorkspaceId = await getCurrentWorkspaceId(router.query);
      const staff = staffs.find(({ workspace_id: wsId }) => currentWorkspaceId === wsId) || staffs[0] || null;
      setCurrentStaff(staff);
      return true;
    } catch (err) {
      catchApiError(err);
      return false;
    } finally {
      setLoading(false);
    }
  }, [appUserApi, router.asPath]);

  const syncStaffMe = useCallback(async () => {
    setLoading(true);
    try {
      const result = await appWorkspaceUserApi.getWorkspaceUserMe({
        workspace_id: currentStaff?.workspace_id || "",
      });
      setCurrentStaff(result.object);
      return true;
    } catch (err) {
      catchApiError(err);
      return false;
    } finally {
      setLoading(false);
    }
  }, [appWorkspaceUserApi]);

  return {
    authSync: authSync,
    fbAuthSync: fbAuthSync,
    authSignUpEmail: authSignUpEmail,
    authSignUpGoogle: authSignUpGoogle,
    authLoginWithEmail: authLoginWithEmail,
    authLoginWithSns: authLoginWithSns,
    authLogout: authLogout,
    syncUserMe: syncUserMe,
    syncStaffMe: syncStaffMe,
  };
};
