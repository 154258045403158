import { zeroPad } from "./common-utils";

export const dayOfWeekStr = (index: number) => ["日", "月", "火", "水", "木", "金", "土"][index]; // 曜日(日本語表記)

export const formatSlashDate = (date?: number | string | Date | null, opts?: { isShortYear?: boolean }) => {
  const dt = date ? new Date(date) : null;
  if (!dt) {
    return "";
  }
  let y = dt.getFullYear().toString();
  if (opts?.isShortYear) {
    y = y.substring(2);
  }
  const m = zeroPad(dt.getMonth() + 1, 2);
  const d = zeroPad(dt.getDate(), 2);
  return `${y}/${m}/${d}`;
};

export const formatDateForAPI = (date?: Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}-${zeroPad(dt.getMonth() + 1, 2)}-${zeroPad(dt.getDate(), 2)}` : "";
};

export const formattedEndDateMilliSec = (date: Date | number, diffDay: number) => {
  if (typeof date === "string") {
    // 期限の連続ペーストで発生することが有る事象に対応
    return;
  }
  if (typeof date === "number") {
    const dt = new Date(date);
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + diffDay, 23, 59, 59, 0).getTime();
  } else {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diffDay, 23, 59, 59, 0).getTime();
  }
};

export const dateToUnixTimestamp = (date?: Date | number | string | null): number => {
  if (!date) {
    return 0;
  }
  const ms = new Date(date).getTime();
  return ms ? ms / 1000 : 0;
};

export const unixTimestampToDate = (unixTimestamp?: number | null): Date | null => {
  if (!unixTimestamp) {
    return null;
  }
  const milliSecValue = unixTimestamp * 1000;
  return new Date(milliSecValue);
};

export const formattedDateAndTimeConverter = (date: Date | null, time: Date | null) => {
  if (!date || !time) {
    return 0;
  }
  return (
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds(),
    ).getTime() / 1000
  );
};

export const formatDateTimeForFileName = (date?: Date | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt
    ? `${dt.getFullYear()}-${zeroPad(dt.getMonth() + 1, 2)}-${zeroPad(dt.getDate(), 2)}_${zeroPad(
        dt.getHours(),
        2,
      )}${zeroPad(dt.getMinutes(), 2)}${zeroPad(dt.getSeconds(), 2)}`
    : "";
};

export const formatDateForJpy = (date?: Date | string | number | null) => {
  if (!date) return "";
  const dt = new Date(date);
  return dt ? `${dt.getFullYear()}年${zeroPad(dt.getMonth() + 1, 2)}月${zeroPad(dt.getDate(), 2)}日` : "";
};

export const nextMonth = (date: Date | string) => {
  const nextMonthDate = new Date(new Date(date).valueOf() + 30 * 24 * 3600 * 1000);
  return formatSlashDate(nextMonthDate);
};

export const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};
