export const prefectureJaList = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
] as const;

export const prefectureEnList = [
  "hokkaido",
  "aomori",
  "iwate",
  "miyagi",
  "akita",
  "yamagata",
  "fukushima",
  "ibaraki",
  "tochigi",
  "gunma",
  "saitama",
  "chiba",
  "tokyo",
  "kanagawa",
  "niigata",
  "toyama",
  "ishikawa",
  "fukui",
  "yamanashi",
  "nagano",
  "gifu",
  "shizuoka",
  "aichi",
  "mie",
  "shiga",
  "kyoto",
  "osaka",
  "hyogo",
  "nara",
  "wakayama",
  "tottori",
  "shimane",
  "okayama",
  "hiroshima",
  "yamaguchi",
  "tokushima",
  "kagawa",
  "ehime",
  "kochi",
  "fukuoka",
  "saga",
  "nagasaki",
  "kumamoto",
  "oita",
  "miyazaki",
  "kagoshima",
  "okinawa",
] as const;

export type PrefectureJa = (typeof prefectureJaList)[number];

export type PrefectureEn = (typeof prefectureEnList)[number];

export const prefectureJpEnMap: Record<PrefectureEn, PrefectureJa> = {
  ["hokkaido"]: "北海道",
  ["aomori"]: "青森県",
  ["iwate"]: "岩手県",
  ["miyagi"]: "宮城県",
  ["akita"]: "秋田県",
  ["yamagata"]: "山形県",
  ["fukushima"]: "福島県",
  ["ibaraki"]: "茨城県",
  ["tochigi"]: "栃木県",
  ["gunma"]: "群馬県",
  ["saitama"]: "埼玉県",
  ["chiba"]: "千葉県",
  ["tokyo"]: "東京都",
  ["kanagawa"]: "神奈川県",
  ["niigata"]: "新潟県",
  ["toyama"]: "富山県",
  ["ishikawa"]: "石川県",
  ["fukui"]: "福井県",
  ["yamanashi"]: "山梨県",
  ["nagano"]: "長野県",
  ["gifu"]: "岐阜県",
  ["shizuoka"]: "静岡県",
  ["aichi"]: "愛知県",
  ["mie"]: "三重県",
  ["shiga"]: "滋賀県",
  ["kyoto"]: "京都府",
  ["osaka"]: "大阪府",
  ["hyogo"]: "兵庫県",
  ["nara"]: "奈良県",
  ["wakayama"]: "和歌山県",
  ["tottori"]: "鳥取県",
  ["shimane"]: "島根県",
  ["okayama"]: "岡山県",
  ["hiroshima"]: "広島県",
  ["yamaguchi"]: "山口県",
  ["tokushima"]: "徳島県",
  ["kagawa"]: "香川県",
  ["ehime"]: "愛媛県",
  ["kochi"]: "高知県",
  ["fukuoka"]: "福岡県",
  ["saga"]: "佐賀県",
  ["nagasaki"]: "長崎県",
  ["kumamoto"]: "熊本県",
  ["oita"]: "大分県",
  ["miyazaki"]: "宮崎県",
  ["kagoshima"]: "鹿児島県",
  ["okinawa"]: "沖縄県",
};

export const prefectureEnJaMap: Record<PrefectureJa, PrefectureEn> = {
  ["北海道"]: "hokkaido",
  ["青森県"]: "aomori",
  ["岩手県"]: "iwate",
  ["宮城県"]: "miyagi",
  ["秋田県"]: "akita",
  ["山形県"]: "yamagata",
  ["福島県"]: "fukushima",
  ["茨城県"]: "ibaraki",
  ["栃木県"]: "tochigi",
  ["群馬県"]: "gunma",
  ["埼玉県"]: "saitama",
  ["千葉県"]: "chiba",
  ["東京都"]: "tokyo",
  ["神奈川県"]: "kanagawa",
  ["新潟県"]: "niigata",
  ["富山県"]: "toyama",
  ["石川県"]: "ishikawa",
  ["福井県"]: "fukui",
  ["山梨県"]: "yamanashi",
  ["長野県"]: "nagano",
  ["岐阜県"]: "gifu",
  ["静岡県"]: "shizuoka",
  ["愛知県"]: "aichi",
  ["三重県"]: "mie",
  ["滋賀県"]: "shiga",
  ["京都府"]: "kyoto",
  ["大阪府"]: "osaka",
  ["兵庫県"]: "hyogo",
  ["奈良県"]: "nara",
  ["和歌山県"]: "wakayama",
  ["鳥取県"]: "tottori",
  ["島根県"]: "shimane",
  ["岡山県"]: "okayama",
  ["広島県"]: "hiroshima",
  ["山口県"]: "yamaguchi",
  ["徳島県"]: "tokushima",
  ["香川県"]: "kagawa",
  ["愛媛県"]: "ehime",
  ["高知県"]: "kochi",
  ["福岡県"]: "fukuoka",
  ["佐賀県"]: "saga",
  ["長崎県"]: "nagasaki",
  ["熊本県"]: "kumamoto",
  ["大分県"]: "oita",
  ["宮崎県"]: "miyazaki",
  ["鹿児島県"]: "kagoshima",
  ["沖縄県"]: "okinawa",
};
